<template>
  <v-app>
    <!-- Load spinner -->
    <div id="appLoader" v-show="!dealerJSON && !errorJSON">
      <v-progress-circular id="appLoaderInner" class="loader" :size="150" :width="10" color="#000000" indeterminate></v-progress-circular>
    </div>
    <!-- Loading failed -->
    <LoadFailure id="loadFailHome" v-if="errorJSON"></LoadFailure>
    <!-- Main menu overlay -->
    <MainMenuMobile v-if="dealerJSON" v-model="showMainMenu" @toggleMainMenu="toggleMainMenu()" />
    <!-- Router content -->
    <v-main id="main-content" v-if="dealerJSON">
      <router-view class="router-page" transition="fade-transition" @toggleMainMenu="toggleMainMenu()"></router-view>
      <!-- Cookie message popup -->
      <CookiePopUp :cookieDialog="cookieDialog" @acceptCookies="acceptCookies()"/>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'App',
  components: {
    MainMenuMobile: () => import('@/components/mainMenu/MainMenuMobile.vue'),
    CookiePopUp: () => import('@/components/CookiePopUp.vue'),
    LoadFailure: () => import('@/components/LoadFailure.vue'),
  },
  data: () => ({
    apiConfig: {headers: {'Authorization':'Bearer ' + process.env.VUE_APP_API_TOKEN}},
    cookieDialog:false,
    cookieValue: "cookieconsent=true",
    routes: [],
    showMainMenu: false
  }),
  mounted() {
    this.readCookies(); // read cookies for cookie popup function
  },
  watch: {
    '$route' (to, from) {
      // when route changes in url, fire the following
      this.$gtm.trackView(to.path, to.path); // google tag manager event fired.
      return from;
    }
  },
  methods: {
    acceptCookies() {
      this.cookieDialog = !this.cookieDialog;
      var cookieDate = new Date;
      cookieDate.setFullYear((cookieDate.getFullYear() + 1));
      document.cookie = this.cookieValue + ';expires=' + cookieDate.toUTCString() + ';'; // GDPR consent cookie less than 13 months. Afterwards need a new consent.
    },
    readCookies() {
      if (document.cookie.split(';').filter(function(item) {
        return item.indexOf('cookieconsent=true') >= 0
      }).length) {
          // condition if passed
      }
      else {
        // if theres no cookie enable popup
        this.cookieDialog = true;
      }
    },
    toggleMainMenu() {
      this.showMainMenu = !this.showMainMenu;
    }
  },
  computed: {
    ...mapState({
      dealerJSON: state => state.dealerJSON, // get data from store
      dealerConfigData: state => state.dealerConfigData, // get data from store
      themeJSON: state => state.themeJSON, // get data from store
      errorJSON: state => state.errorJSON
    })
  }
};
</script>

<style lang="scss">
  *, *:before, *:after {
    box-sizing: border-box;
  }
  #appLoader {
    position: absolute;
    width: 100%;
    height: 100%;
    .loader {
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
    }
  }
  #loadFailHome {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
  }
  // app bar at top of page
  .company-wrapper {
    .company-name {
      align-self:center;
    }
  }
  // wrapper of all the content that gets rendered inside. Including: router-view, footer, cookie-popup
  #main-content {
    // direct child wrapper of #main-content
    .v-main__wrap {
      display: flex;
      min-height: 100%;
      flex-direction: column;
      // router-page is direct child
      .router-page {
        flex:1;
      }
    }
  }
  // width settings of a regular page.
  .page-size {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  @media only screen and (max-width:600px) {
    header {
      box-shadow: none !important; // disable shadow in app bar on mobile view
    }
  }
  @media only screen and (min-width:1200px) {
    .company-wrapper {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  // Override lines
  // td in step 2 form, standard set on 14px font-size
  div.v-data-table td {
    //font-size: 16px;
  }
</style>