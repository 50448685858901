import Vue from 'vue';
import App from './App.vue';
import VueGtm from 'vue-gtm';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import axios from 'axios';
import VueRouter from 'vue-router';
import store from './store';

function lazyLoadView(viewname) {
  return() => import('@/views/' + viewname + '.vue')
}

Vue.use(VueGtm, {
  id:'GTM-NGM49SV'
})                  // enable use of VueGtm
Vue.use(VueMeta);   // enable use of VueMeta
Vue.config.productionTip = false
Vue.use(VueRouter)  // enable use of VueRouter

const routes = [
  {
    name: 'home',
    path: '/',
    component: lazyLoadView('Home')
  },
  {
    name: 'planner',
    path: '/werkplaatsplanner',
    component: lazyLoadView('Planner'),
    children: [
      {
        name: 'planner-voertuig',
        path: 'voertuig',
        meta: {
          currentStep: 1
        }
      },
      {
        name: 'planner-onderhoud',
        path: 'onderhoud',
        meta: {
          currentStep: 2
        }
      },
      {
        name: 'planner-datum',
        path: 'datum-tijd',
        meta: {
          currentStep: 3
        }
      },
      {
        name: 'planner-gegevens',
        path: 'gegevens',
        meta: {
          currentStep: 4
        }
      },
      {
        name: 'planner-eind',
        path: 'eind',
        meta: {
          currentStep: 5
        }
      }
    ]
  },
  {
    name: 'error',
    path: "/404",
    alias: "*",
    component: lazyLoadView('Error')
  }
]

// Load assets from public/theme/{themename}/assets.json to <head> tag, before the instance of the application is created.
function loadCss(rel, filename) {
  var doc = document.createElement('link');
  doc.rel = rel;
  if (rel == 'preload') {
    doc.as = "style"; // add attribute as="style"
  }
  doc.href = filename;
  document.head.appendChild(doc);
}
function loadFont(filename) {
  var doc = document.createElement('link');
  doc.rel = 'prefetch'; // can be 'preload', but chrome warns about loading too late. Hence 'prefetch'.
  doc.href = filename;
  doc.as = 'font';
  doc.setAttribute('crossorigin', 'anonymous');
  document.head.appendChild(doc);
}
/*
function loadJs(filename) {
  var doc = document.createElement('script');
  doc.type = 'text/javascript';
  doc.async = true;
  doc.src = filename;
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(doc, s);
}
*/

// Grab current URL.
var currentUrl = window.location.host;

// if development environment
if (currentUrl.indexOf('localhost') >= 0 || currentUrl.indexOf('labs') >= 0 || currentUrl.indexOf('192.168.') >= 0) {
  currentUrl = 'dev';
}
// Get dealerConfigData
function getConfigData() {
  return new Promise((resolve) => {
    axios.get(
      "/cfg/" + currentUrl + "/env.json"
    ).then((response) => {
      store.commit('setConfigData', response.data); // add response to dealerConfigData store variable
      document.title = store.state.dealerConfigData.initial_page_title; // set page title
      resolve(response.data); // promise reached when there is data from response
    })
  })
}
function getDealerData() {
  return new Promise((resolve) => {
    var api_url = store.state.dealerConfigData.api.url;
    var api_dealer = store.state.dealerConfigData.api.dealer;
    var api_dealer_id = store.state.dealerConfigData.api.dealer_id;
    // Get data from the current dealer
    axios.get(
      api_url + api_dealer + api_dealer_id,
      {headers: {'Authorization':'Bearer ' + process.env.VUE_APP_API_TOKEN}}
    ).then((response) => {
      store.commit('setDealerJSON', response.data); // data goes to store dealerJSON;
      // Add Category routes / pathes

      var categories = response.data.categories;
      for (var i = 0; i < categories.length; i++) {
        var route = {
          name: categories[i].url_alias,
          path: '/' + categories[i].url_alias,
          component: lazyLoadView('Category'),
          meta: {category: categories[i].url_alias}
          // needed: ,
        };
        // push category urls in to routes var
        routes.push(route);
      }
      // Add voucher routes / paths
      var vouchers = response.data.data;
      for (var j = 0; j < vouchers.length; j++) {
        route = {
          name: 'voucher' + vouchers[j].voucher_id,
          path: vouchers[j].url_alias,
          component: lazyLoadView('Download'),
          meta: {voucher_id: vouchers[j].voucher_id},
          children:[
            {name: 'voucher' + vouchers[j].voucher_id + '-stap1', path: 'stap1', meta: {voucher_id: vouchers[j].voucher_id, currentStep: 1}},
            {name: 'voucher' + vouchers[j].voucher_id + '-stap2', path: 'stap2', meta: {voucher_id: vouchers[j].voucher_id, currentStep: 2}},
            {name: 'voucher' + vouchers[j].voucher_id + '-bedankt', path: 'bedankt', meta: {voucher_id: vouchers[j].voucher_id, currentStep: 3}}]
        };
        // push voucher urls in to routes var
        routes.push(route);
      }
      // Add pages routes / paths
      var pages = response.data.pages;
      for (var k = 0; k < pages.length; k++) {
        route = {
          name: 'page' + [k],
          path: pages[k].url_alias,
          component: lazyLoadView('Page'),
          meta: {name: pages[k].menu_name}
        };
        // push page urls in to routes var
        routes.push(route);
      }
      resolve(response.data); // promise reached when there is data from response
    }).catch( // end axios "then"
      function (error) {
        if (error.response) {
          /* eslint-disable */
          console.log("Request made and server responded");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("Voucher App CRC custom error report: the request was made but no response was received");
          console.log(error.request);
        } else {
          console.log("Error: ", error.message);
        }
        store.commit('setErrorJSON', error); // data goes to store dealerJSON;
        resolve(error); // promised reached when an error is returned
      }
    ) // end axios catch
  }) // end promise
}
function getAssetData() {
  return new Promise((resolve) => {
    var themeDir = store.state.dealerConfigData.theme.dir + store.state.dealerConfigData.theme.name; // dir path to theme directory

    axios.get(
      themeDir + "/assets.json"
    ).then((response) => {
      store.commit('setThemeJSON', response.data); // add response to dealerConfigData store variable
      var assetData = response.data;
      // Load all fonts set in public/theme/{themename}/assets.js
      if (assetData.fonts) {
        for(var i = 0;assetData.fonts.files.length > i;i++) {
          // load all text and icon fonts
          loadFont(themeDir + assetData.fonts.dir + assetData.fonts.files[i].name);
        }
      }
      // Load all css files set in public/theme/{themename}/assets.js
      if (assetData.css) {
        for(var j = 0;assetData.css.files.length > j;j++) {
          if (assetData.css.files[j].type == "local") {
            loadCss(assetData.css.files[j].rel, themeDir + assetData.css.dir + assetData.css.files[j].name);
          } else if (assetData.css.files[j].type == "external") {
            loadCss(assetData.css.files[j].rel, assetData.css.files[j].name);
          }
        }
      }
      // Load all favicon images set in public/theme/{themename}/assets.js
      if (assetData.favicons) {
        for (var k = 0;k < assetData.favicons.files.length;k++) {
          let recaptchaScript = document.createElement('link');
          recaptchaScript.setAttribute('href', '.' + themeDir + assetData.favicons.dir + assetData.favicons.files[k].href);
          recaptchaScript.setAttribute('rel', assetData.favicons.files[k].rel);
          if (assetData.favicons.files[k].type !== "empty") {
            recaptchaScript.setAttribute('type', assetData.favicons.files[k].type);
          }
          if (assetData.favicons.files[k].sizes !== "empty") {
            recaptchaScript.setAttribute('sizes', assetData.favicons.files[k].sizes);
          }
          document.head.appendChild(recaptchaScript);
        }
      }
      // Load from assets the color and svg_code for appLoader spinner when opening website.
      if (assetData.loader) {
        var appLoaderInner = document.createElement('style');
        appLoaderInner.appendChild(document.createTextNode('#appLoaderInner {background-image: url(' + assetData.loader.svg_code + ');color: '+ assetData.loader.color + ' !important;background-size:' + assetData.loader.icon_size + ';background-position: center;}'));
        document.head.appendChild(appLoaderInner);
      }
      resolve(response.data); // promise reached when there is data from response
    }) // end axios "then"
  }) // end promise
}

async function instanciateVue() {
  await getConfigData(); // This function (with a promise function) will resolve(aka return) "response.data". After this resolve has been met, the rest of the code can run.
  await getAssetData();  // This function (with a promise function) will resolve(aka return) "response.data". After this resolve has been met, the rest of the code can run.
  await getDealerData(); // This function (with a promise function) will resolve(aka return) "response.data". After this resolve has been met, the rest of the code can run.

  // instanciate VueRouter, add routes variable data.
  const router = new VueRouter({
    mode: 'history',
    routes
  })
  // instanciate Vue the application. Add the router data to the instance.
  new Vue({
    router,
    vuetify,
    store,
    render: h => h(App) // render: h => h(App, {props: {newVueCurrentDealer: currentDealer}}) // example with passing props, incase
  }).$mount('#app')
}
instanciateVue(); // run function to create instance of Vue
