import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex); // enable use of Vuex

const store =  new Vuex.Store({
  state: {
    dealerJSON: null,
    dealerConfigData: null,
    themeJSON: null,
    errorJSON: null,
  },
  mutations: {
    setDealerJSON (state, data) {
      state.dealerJSON = data; // store data into dealerJSON state.
    },
    setConfigData (state, data) {
      state.dealerConfigData = data; // store data into configData state.
    },
    setThemeJSON (state, data) {
      state.themeJSON = data; // store theme data into themeJSON
    },
    setErrorJSON (state, data) {
      state.errorJSON = data; // store theme data into themeJSON
    }
  }
});
export default store;